import React from 'react';

import ViolationList from '../../components/violationList';

export default function Analyser({ violations }) {
  return (
    <div>
      <ViolationList violations={violations} />
    </div>
  );
}
