import React, { createContext, useState, useMemo, useContext } from 'react';

const LogContext = createContext();

export function LogProvider({ children }) {
  const [logMessages, setLogMessages] = useState([]);

  const addLogMessage = (message) => {
    const timestamp = new Date();
    setLogMessages((prevLogs) => [{ message, timestamp }, ...prevLogs]);
  };

  const value = useMemo(() => ({ logMessages, addLogMessage }), [logMessages]);

  return <LogContext.Provider value={value}>{children}</LogContext.Provider>;
}

export const useLog = () => {
  return useContext(LogContext);
};
