import React from 'react';
import ViolationItem from './violationItem';

export default function ViolationList({ violations }) {
  const groupedViolations = violations.reduce((acc, violation) => {
    if (!acc[violation.rule]) {
      acc[violation.rule] = [];
    }
    acc[violation.rule].push(violation);
    return acc;
  }, {});

  return (
    <div>
      <h2>Violations List</h2>
      {Object.entries(groupedViolations).map(([rule, ruleViolations]) => (
        <div key={rule}>
          <h3>{rule}</h3>
          {ruleViolations.map((violation) => (
            <ViolationItem key={violation.fingerprint} violation={violation} />
          ))}
        </div>
      ))}
    </div>
  );
}
