import React from 'react';
import '../ui/components/violationItem.scss';
import { useLog } from '../context/LogContext';

export default function ViolationItem({ violation }) {
  const { addLogMessage } = useLog();

  const handleSendMessage = () => {
    const data = {
      type: 'violationDetails',
      payload: violation,
    };

    const message = 'message';

    addLogMessage(`Sending message to Studio Plugin: ${JSON.stringify(message)}`);

    if (window.chrome?.webview) {
      addLogMessage('Sent using window.chrome');
      window.chrome.webview.postMessage({ message, data });
    } else if (window.webkit?.messageHandlers.studioPro) {
      addLogMessage('Sent using window.webkit');
      window.webkit.messageHandlers.studioPro.postMessage(JSON.stringify({ message, data }));
    } else {
      addLogMessage('No supported messaging interface found.');
    }
  };

  return (
    <div className="violation-item">
      <p>
        <strong>Rule:</strong> {violation.rule}
      </p>
      <p>
        <strong>Message:</strong> {violation.message}
      </p>
      <p>
        <strong>Path to Parent:</strong> {violation.pathToParent}
      </p>
      <p>
        <strong>Unit Name:</strong> {violation.unitName}
      </p>
      {violation.debugInformation && (
        <p>
          <strong>Debug Information:</strong> {violation.debugInformation}
        </p>
      )}
      <button type="button" onClick={handleSendMessage}>
        Go To
      </button>
    </div>
  );
}
