import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Analyser from './analyser';

const POLL_INTERVAL = 5000;

function AnalyserPage() {
  const { jobId } = useParams();
  const [violations, setViolations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const baseUrl = process.env.REACT_APP_ANALYSER_URL;

  useEffect(() => {
    let intervalId;

    async function fetchJobStatus() {
      if (!jobId) {
        setError('Job ID is missing in the URL.');
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(`${baseUrl}/api/public/mprAnalyseJob/${jobId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const jobData = await response.json();

        if (jobData.status === 'COMPLETED') {
          setViolations(jobData.result || []);
          setLoading(false);
          clearInterval(intervalId);
        } else if (jobData.status === 'FAILED') {
          setError('Job failed.');
          setLoading(false);
          clearInterval(intervalId);
        }
      } catch (err) {
        setError(err.message);
        setLoading(false);
        clearInterval(intervalId);
      }
    }

    fetchJobStatus();

    intervalId = setInterval(() => {
      fetchJobStatus();
    }, POLL_INTERVAL);

    return () => clearInterval(intervalId);
  }, [jobId, baseUrl]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return <Analyser violations={violations} />;
}

export default AnalyserPage;
