import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useLog } from '../context/LogContext';
import '../ui/components/OnscreenLog.scss';

export default function OnscreenLog() {
  const { logMessages } = useLog();

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString();
  };

  return (
    <div className="onscreen-log-container">
      <div className="log-messages">
        {logMessages.map(({ message, timestamp }) => (
          <p className="log-message" key={uuidv4()}>
            <span className="log-timestamp">[{formatTimestamp(timestamp)}]</span>
            {message}
          </p>
        ))}
      </div>
    </div>
  );
}
