import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import AnalyserPage from './pages/analyser/analyserPage';
import OnscreenLog from './components/OnscreenLog';

function App() {
  const showOnscreenLog = process.env.REACT_APP_ENABLE_ONSCREEN_LOG === 'true';

  return (
    <div className="app">
      {showOnscreenLog && <OnscreenLog />}
      <Router>
        <Routes>
          <Route path="/analyser/:jobId" element={<AnalyserPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
